<template>
    <div>
        <el-form ref="form" :model="criteria" label-width="80px">
            <el-row>
                <el-col :span="4">
                    <div><el-button type="primary" @click="showdialog()">添加配置</el-button></div>
                </el-col>
            </el-row>
        </el-form>
        <el-table :data="tableData">
            <el-table-column prop="Id" label="配置ID" width="140"> </el-table-column>
            <el-table-column prop="config" label="配置数据"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
                <template #default="scope">
                    <el-button round @click="Upload(scope.row)">修改</el-button>
                    <el-popconfirm title="确定删除配置吗?" @confirm="deleteProject(scope.row)">
                        <template #reference>
                            <el-button type="danger" round>删除</el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog v-model="configVisible" title="功能配置" width="500">
            <div class="checkList-css">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="功能选择" name="功能选择">
                        <el-row>
                            <el-col :span="4">
                                <div><el-button type="primary" @click="newName()">起号</el-button></div>
                            </el-col>
                            <el-col :span="4">
                                <div><el-button type="primary" @click="daily()">日常一条龙</el-button></div>
                            </el-col>
                            <el-col :span="4">
                                <div><el-button type="primary" @click="empty()">清空任务</el-button></div>
                            </el-col>
                        </el-row>
                        <el-checkbox-group v-model="formdata.checkList">
                            <el-checkbox v-for="(item1, index) in configList" :key="index" :label="item1.id"
                                :value="item1.id">
                                <span>{{ item1.name }}</span>
                                <!-- <el-select v-model="item1.value" placeholder="Select" size="large" style="width: 120px">
                            <el-option v-for="item in configList" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select> -->
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-tab-pane>
                    <el-tab-pane label="捉鬼配置" name="捉鬼配置">
                        <div class="mb-2 ml-4">
                            <el-radio v-model="formdata.zhuoguiradio" label="1">带队捉鬼</el-radio>
                            <el-radio v-model="formdata.zhuoguiradio" label="2">混队捉鬼</el-radio>
                            <el-form ref="form" :model="form" label-width="120px" style="margin-top:10px;">
                                <el-form-item label="请输入捉鬼次数">
                                    <el-input v-model="formdata.zhuoguicishu" placeholder="请输入捉鬼次数"
                                        style="width: 200px;"></el-input>
                                </el-form-item>
                                <p style="padding-bottom: 10px;color: #999;">修炼技能的时候，大于输入银币数量才去学习</p>
                                <el-form-item label="银币数量">
                                    <el-input v-model="formdata.jinengyinbi" placeholder="请输入银币"
                                        style="width: 200px;"></el-input>
                                </el-form-item>
                                <p style="padding-bottom: 10px;color: #999;">购买神兜兜不大于输入价格</p>
                                <el-form-item label="神兜兜价格">
                                    <el-input v-model="formdata.shendoudodyinbi" placeholder="请输入神兜兜价格"
                                        style="width: 200px;"></el-input>
                                </el-form-item>
                                <p style="padding-bottom: 10px;color: #999;">第二天定时启动；请输入0-23;</p>
                                <el-form-item label="定时启动">
                                    <el-input v-model="formdata.dingshi" placeholder="定时启动"
                                        style="width: 200px;"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="洗宝宝" name="洗宝宝">
                        <div class="mb-2 ml-4">
                            <el-form ref="form" :model="form" label-width="120px" style="margin-top:10px;">
                                <el-form-item label="洗宝宝">
                                    <el-select v-model="formdata.bbvalue" placeholder="请选择">
                                        <el-option v-for="item in options" :key="item.id" :label="item.label"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="3技能">
                                    <el-input v-model="formdata.bbprice" placeholder="宝宝价格"
                                        style="width: 200px;margin-left: 5px;"></el-input>
                                </el-form-item>
                                <el-form-item label="4技能">
                                    <el-input v-model="formdata.bbprice1" placeholder="宝宝价格"
                                        style="width: 200px;margin-left: 5px;"></el-input>
                                </el-form-item>
                                <el-form-item label="5技能">
                                    <el-input v-model="formdata.bbprice2" placeholder="宝宝价格"
                                        style="width: 200px;margin-left: 5px;"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                
                    </el-tab-pane>
                    <el-tab-pane label="活力配置" name="活力配置">
                        <el-radio v-model="formdata.dagong" label="1">不做</el-radio>
                        <el-radio v-model="formdata.dagong" label="2">打工</el-radio>
                        <el-radio v-model="formdata.dagong" label="3">制符 </el-radio>
                        <el-radio v-model="formdata.dagong" label="4"> 烹饪 </el-radio>
                        <el-radio v-model="formdata.dagong" label="5"> 制药 </el-radio>
                        <el-radio v-model="formdata.dagong" label="6"> 打造 </el-radio>
                        <el-radio v-model="formdata.dagong" label="7">裁缝</el-radio>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="configVisible = false">取消</el-button>
                    <el-button type="primary" @click="handleClose">
                        保存配置
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
  
<script>
import { deletefunctionconfig, addfunctionconfig, upfunctionconfig, getfunctionconfig } from "@/api/script";
export default {
    data() {
        return {
            tableData: [],
            configVisible: false,
            activeName: '功能选择',
            configList: [
                { id: 1, name: "起号35级", value: 1 },
                { id: 2, name: "领取福利", value: 2 },
                { id: 3, name: "主线任务", value: 3 },
                { id: 4, name: "宝塔任务", value: 4 },
                { id: 5, name: "领取成长", value: 5 },
                { id: 6, name: "助战管理", value: 6 },
                { id: 7, name: "修理装备", value: 7 },
                { id: 8, name: "师门任务", value: 8 },
                { id: 9, name: "宝图任务", value: 9 },
                { id: 10, name: "秘境任务", value: 10 },
                { id: 11, name: "运镖", value: 11 },
                { id: 12, name: "三界奇缘", value: 12 },
                { id: 13, name: "科举乡试", value: 13 },
                { id: 14, name: "副本", value: 14 },
                { id: 15, name: "捉鬼", value: 15 },
                { id: 16, name: "解锁包裹", value: 16 },
                { id: 17, name: "经验链", value: 17 },
                { id: 18, name: "升级技能", value: 18 },
                { id: 19, name: "镶嵌宝石", value: 19 },
                { id: 20, name: "升级修炼", value: 20 },
                { id: 21, name: "商会出售", value: 21 },
                { id: 22, name: "摆摊", value: 22 },
                { id: 23, name: "清理包裹", value: 23 },
                { id: 24, name: "使用活力", value: 24 },
                { id: 25, name: "帮派技能", value: 25 },
                { id: 26, name: "人物加点", value: 26 },
                { id: 27, name: "洗宝宝", value: 27 },
                { id: 28, name: "存神兜兜", value: 28 },
            ],
            options: [
                { id: 1, label: "吸血鬼" },
                { id: 2, label: "猴子" },
                { id: 3, label: "凤凰" },
                { id: 4, label: "布雨" },
                { id: 5, label: "武罗仙子" },
                { id: 6, label: "净瓶女娲" },
                { id: 7, label: "灵符女娲" },
                { id: 8, label: "羽云仙子" },
                { id: 9, label: "鬼剑" },
                { id: 10, label: "菱心仙子" },
                { id: 11, label: "绘舞天女" },
            ],
            formdata: {
                zhuoguiradio: '1',
                checkList: [],
                zhuoguicishu: 20,
                jinengyinbi: 1000000,
                shendoudodyinbi: 60000,
                bbvalue: '',
                bbprice: '',
                bbprice1: '',
                bbprice2: '',
                dagong: 1,
                dingshi:"0",
                userid: this.$store.state.loginName.id
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            getfunctionconfig({ userid: this.$store.state.loginName.id }).then(res => {
                if (res.status === 200) {
                    this.tableData = res.data
                }
            })
        },
        empty() {
            this.formdata.checkList = [];
        },
        newName() {
            this.formdata.checkList = [];
            this.formdata.checkList = [1, 7, 8, 9, 10, 11, 12, 13, 14, 15, 18, 20, 21, 22, 23, 24]
        },
        daily() {
            this.formdata.checkList = [];
            this.formdata.checkList = [7, 8, 9, 10, 11, 12, 13, 14, 15, 18, 20, 21, 22, 23, 24]
        },
        showdialog() {
            this.configVisible = true
        },
        handleClose(done) {
            this.formdata.checkList.sort((a, b) => a - b)
            this.$confirm('确认保存关闭？')
                .then(_ => {
                    this.configVisible = true
                    if (this.formdata.Id) {
                        upfunctionconfig(this.formdata).then(res => {
                            if (res.status === 200) {
                                this.configVisible = false
                                this.init();
                            }
                        })
                    } else {
                        addfunctionconfig(this.formdata).then(res => {
                            if (res.status === 200) {
                                this.configVisible = false
                                this.init();
                            }
                        })
                    }
                    this.init()
                })
                .catch(_ => { });
        },
        Upload(item) {
            this.showdialog()
            console.log(item)
            this.formdata = Object.assign({},this.formdata,JSON.parse(item.config))
            if(JSON.parse(item.config).checkList){
                this.formdata.checkList = this.convertStringsToNumbers(JSON.parse(item.config).checkList)
            }
            this.formdata.Id = item.Id
            this.formdata.bbvalue = parseInt(this.formdata.bbvalue)
        },
        convertStringsToNumbers(arr) {
            for (let i = 0; i < arr.length; i++) {
                if (typeof arr[i] === 'string') {
                    arr[i] = parseInt(arr[i]); // 或者使用parseFloat(arr[i])转为浮点数
                }
            }
            return arr;
        },
        deleteProject(data) {
            console.log(data)
            deletefunctionconfig({ id: data.Id }).then((res) => {
                if (res.status === 200) {
                    console.log("删除成功")
                    this.init()
                }
            });
        },
    }
};
</script>
<style>
.checkList-css .el-checkbox {
    margin-top: 10px;
    width: 20%;
}
</style>